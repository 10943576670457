import * as React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { COLORS } from '../constants/appConstants';
import { SvgIcon } from './svgIcon';

export const BottomTabBarItem = (props) => {
  const { t } = useTranslation();
  const activeStyles = props.isActive ? { backgroundColor: props.route.colorTheme } : {};
  const fill = props.isActive ? props.route.colorTheme : COLORS.WHITE;
  const path = {
    pathname: props.route.config.path,
  }
  if (props.route.bottomTabBarQueryString) {
    path.search = props.route.bottomTabBarQueryString;
  }

  return (
    <Link to={path}>
      <div className="o-tab-button">
        <SvgIcon name={props.route.bottomTabBarImage} fill={fill} />
        <span className="l-margin__top17 t-bold__26 color-white">
          {t(props.route.nameKey)}
        </span>
        <div className="o-tab-button-line" style={activeStyles} />
      </div>
    </Link>
  );
};
